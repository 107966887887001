/*
@license

dhtmlxGantt v.7.0.9 Professional

This software is covered by DHTMLX Enterprise License. Usage without proper license is prohibited.

(c) XB Software Ltd.

*/
.buttonBg {
    background: #fff;
  }
  /* colors for items with inline styles assigned (task.color, link.color)*/
  .gridHoverStyle {
    background-color: #FFF3A1;
  }
  .gridSelection {
    background-color: #FFF3A1;
  }
  .timelineSelection {
    background-color: #FFF3A1;
  }
  .gantt_grid_scale .gantt_grid_head_cell {
    color: #a6a6a6;
    border-top: none !important;
    border-right: none !important;
  }
  .gantt_grid_data .gantt_cell {
    border-right: none;
    color: #454545;
  }
  /*
      Tasks
  */
  .gantt_task_link .gantt_link_arrow_right {
    border-width: 6px 6px 6px 6px;
    margin-top: -3px;
  }
  .gantt_task_link .gantt_link_arrow_left {
    border-width: 6px 6px 6px 6px;
    margin-left: -6px;
    margin-top: -3px;
  }
  .gantt_task_link .gantt_link_arrow_up {
    border-width: 6px 6px 6px 6px;
  }
  .gantt_task_link .gantt_link_arrow_down {
    border-width: 6px 6px 6px 6px;
  }
  .gantt_task_line .gantt_task_progress_drag {
    bottom: -4px;
    height: 10px;
    margin-left: -8px;
    width: 16px;
  }
  .chartHeaderBg {
    background-color: #fff;
  }
  .gantt_task .gantt_task_scale .gantt_scale_cell {
    color: #a6a6a6;
    border-right: 1px solid #ebebeb;
  }
  /*
      project highlight
  */
  .gantt_row.gantt_project,
  .gantt_row.odd.gantt_project {
    background-color: #edffef;
  }
  .gantt_task_row.gantt_project,
  .gantt_task_row.odd.gantt_project {
    background-color: #f5fff6;
  }
  .gantt_task_line.gantt_project {
    background-color: #65c16f;
    border: 1px solid #3c9445;
  }
  .gantt_task_line.gantt_project .gantt_task_progress {
    background-color: #46ad51;
  }
  /*
      milestone
  */
  /*
      lightbox
  */
  .buttonBg {
    background: #fff;
  }
  .gantt_cal_light .gantt_btn_set {
    margin: 5px 10px;
  }
  .gantt_btn_set.gantt_cancel_btn_set {
    background: #fff;
    color: #454545;
    border: 1px solid #CECECE;
  }
  .gantt_btn_set.gantt_save_btn_set {
    background: #3db9d3;
    text-shadow: 0 -1px 0 #248a9f;
    color: #fff;
  }
  .gantt_btn_set.gantt_delete_btn_set {
    text-shadow: 0 -1px 0 #6F6F6F;
    background: #ec8e00;
    text-shadow: 0 -1px 0 #aa6600;
    color: #fff;
  }
  .gantt_delete_btn {
    margin-top: 2px;
    width: 20px;
  }
  .gantt_cal_light_wide {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .gantt_cal_light_wide .gantt_cal_larea {
    border-left: none !important;
    border-right: none !important;
  }
  /*
      Message
  */
  .gantt_popup_button.gantt_ok_button {
    background: #3db9d3;
    text-shadow: 0 -1px 0 #248a9f;
    color: #fff;
    font-weight: bold;
    border-width: 0;
  }
  .gantt_popup_button.gantt_cancel_button {
    font-weight: bold;
    color: #454544;
  }
  .gantt_popup_title {
    background-color: #fff;
  }
  .gantt_popup_shadow {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
  }
  /*
      Quick info
  */
  .gantt_qi_big_icon.icon_edit {
    color: #454545;
    background: #fff;
  }
  .gantt_qi_big_icon.icon_delete {
    text-shadow: 0 -1px 0 #aa6600;
    background: #ec8e00;
    color: #fff;
    border-width: 0;
  }
  /*links dnd*/
  .gantt_tooltip {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.07);
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    font-family: "Arial";
    font-size: 8pt;
    color: #454545;
  }
  .gantt_container {
    background-color: #FFFFFF;
    font-family: "Arial";
    font-size: 13px;
    border: 1px solid #CECECE;
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .gantt_task_scroll {
    overflow-x: scroll;
  }
  .gantt_task,
  .gantt_grid {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    display: inline-block;
    vertical-align: top;
  }
  .gantt_grid_scale,
  .gantt_task_scale {
    color: #6b6b6b;
    font-size: 12px;
    border-bottom: 1px solid #CECECE;
    box-sizing: border-box;
  }
  .gantt_grid_scale {
    background-color: #fff;
  }
  .gantt_task_scale {
    background-color: #fff;
  }
  .gantt_task_vscroll {
    background-color: #fff;
  }
  .gantt_scale_line {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-top: 1px solid #CECECE;
  }
  .gantt_scale_line:first-child {
    border-top: none;
  }
  .gantt_grid_head_cell {
    display: inline-block;
    vertical-align: top;
    border-right: 1px solid #CECECE;
    text-align: center;
    position: relative;
    cursor: default;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    line-height: 33px;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
  }
  .gantt_scale_line {
    clear: both;
  }
  .gantt_grid_data {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .gantt_row {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
  }
  .gantt_add,
  .gantt_grid_head_add {
    width: 100%;
    height: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTQ3MjMyMENDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTQ3MjMyMERDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NDcyMzIwQUM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NDcyMzIwQkM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PshZT8UAAABbSURBVHjaYrTdeZmBEsCER+4wEP+H4sPkGGCDg020ARR7gb4GIAcYDKMDdPnDyAbYkGG5DVW9cIQMvUdBBAuUY4vDz8iAcZinA2zgCHqAYQMseAywJcYFAAEGAM+UFGuohFczAAAAAElFTkSuQmCC);
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  .gantt_grid_head_cell.gantt_grid_head_add {
    -moz-opacity: 0.6;
    opacity: 0.6;
    top: 0;
  }
  .gantt_grid_head_cell.gantt_grid_head_add:hover {
    -moz-opacity: 1;
    opacity: 1;
  }
  .gantt_grid_data .gantt_row:hover,
  .gantt_grid_data .gantt_row.odd:hover {
    background-color: #FFF3A1;
  }
  .gantt_grid_data .gantt_row:hover .gantt_add,
  .gantt_grid_data .gantt_row.odd:hover .gantt_add {
    -moz-opacity: 1;
    opacity: 1;
  }
  .gantt_task_row,
  .gantt_row {
    border-bottom: 1px solid #ebebeb;
  }
  .gantt_row,
  .gantt_task_row {
    background-color: #FFFFFF;
  }
  .gantt_row.odd,
  .gantt_task_row.odd {
    background-color: #FFFFFF;
  }
  .gantt_row,
  .gantt_cell,
  .gantt_task_row,
  .gantt_task_cell,
  .gantt_grid_head_cell,
  .gantt_scale_cell {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .gantt_grid_head_cell,
  .gantt_scale_cell {
    line-height: inherit;
  }
  .gantt_grid_scale .gantt_grid_column_resize_wrap {
    cursor: col-resize;
    position: absolute;
    width: 13px;
    margin-left: -7px;
  }
  .gantt_grid_column_resize_wrap .gantt_grid_column_resize {
    background-color: #CECECE;
    height: 100%;
    width: 1px;
    margin: 0 auto;
  }
  .gantt_drag_marker.gantt_grid_resize_area {
    background-color: rgba(231, 231, 231, 0.5);
    border-left: 1px solid #CECECE;
    border-right: 1px solid #CECECE;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  .gantt_row {
    display: -webkit-flex;
    display: flex;
  }
  .gantt_row > div {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
  }
  .gantt_cell {
    vertical-align: top;
    border-right: 1px solid #ebebeb;
    padding-left: 6px;
    padding-right: 6px;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }
  .gantt_cell_tree {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .gantt_grid_scale .gantt_last_cell,
  .gantt_grid_data .gantt_last_cell,
  .gantt_task .gantt_task_scale .gantt_scale_cell.gantt_last_cell,
  .gantt_task_bg .gantt_last_cell {
    border-right-width: 0px;
  }
  .gantt_task .gantt_task_scale .gantt_scale_cell.gantt_last_cell {
    border-right-width: 1px;
  }
  .gantt_task_bg {
    overflow: hidden;
  }
  .gantt_scale_cell {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 1px solid #CECECE;
    text-align: center;
    height: 100%;
  }
  .gantt_task_cell {
    display: inline-block;
    height: 100%;
    border-right: 1px solid #ebebeb;
  }
  .gantt_layout_cell.gantt_ver_scroll {
    width: 0px;
    background-color: transparent;
    height: 1px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    right: 0px;
    z-index: 1;
  }
  .gantt_ver_scroll > div {
    width: 1px;
    height: 1px;
  }
  .gantt_hor_scroll {
    height: 0px;
    background-color: transparent;
    width: 100%;
    clear: both;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .gantt_layout_cell .gantt_hor_scroll {
    position: absolute;
  }
  .gantt_hor_scroll > div {
    width: 5000px;
    height: 1px;
  }
  .gantt_tree_indent,
  .gantt_tree_icon {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }
  .gantt_tree_indent {
    width: 15px;
    height: 100%;
  }
  .gantt_tree_content,
  .gantt_tree_icon {
    vertical-align: top;
  }
  .gantt_tree_icon {
    width: 28px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .gantt_tree_content {
    height: 100%;
    white-space: nowrap;
    min-width: 0;
  }
  .gantt_tree_icon.gantt_open {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAArklEQVQ4T2NkoBJgpJI5DEPAoFOnTv0/c+YMQR+bmJgwmJmZwX2E4bVp06b9j4yMZODg4MBp2I8fPxiWL1/OkJWVNUAGcXJyMnz//h3uQrJdRLFBIAPQAchlJLsIFuCMjIwM////B5sJMoRkg2CuIdtrQcHBDOxsbHBfCQgIMHz48AHO//nrF8O6tWsJR7+7uzsDIxMTznT0/98/hp07d+I3iGopm2DewKFg8OV+AJWkfRMrTobLAAAAAElFTkSuQmCC);
    width: 18px;
    cursor: pointer;
  }
  .gantt_tree_icon.gantt_close {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAkUlEQVQ4T2NkoBJgpJI5DEPAoFOnTv0/c+YMQR+bmJgwmJmZwX2E4bVp06b9j4yMZODg4MBp2I8fPxiWL1/OkJWVNeIN4uTkxAin79+/M5AcRtgCHGQIyQbhijaiDQoKDmZgZ2PDGf0/f/1iWLd2LeHod3d3Z2BkYsJp0P9//xh27tyJ3yCqpWyCeQOHgsGX+wEZpW4T5LCxKwAAAABJRU5ErkJggg==);
    width: 18px;
    cursor: pointer;
  }
  .gantt_tree_icon.gantt_blank {
    width: 18px;
  }
  .gantt_tree_icon.gantt_folder_open {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAs0lEQVQ4T62T0Q2EIBBEpQlzuaaMsQoqooqLsSljbMLLmMxmUXBR4U+Qt7Mzi2sqLVeJ00SgEMKWAnvvzYLyAyHfT5sU2fXDJSwCAXK8MI0/UTkva7IIFJsg3NSwnKdFoKtAWOQ1CN7CEqeTotE5L7QyJhmBcklZM4ZgTiAr3iOU3kD93ppO5SkMjB1EeXdBWoSkRql3YeIRe+cGvktS056JR9wsmeBUkujCfNXWCPC8GugPqn5ii/hV+FoAAAAASUVORK5CYII=);
  }
  .gantt_tree_icon.gantt_folder_closed {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAfElEQVQ4T2NkoBJgpJI5DCgGTZ8+/T82gzMzMwlaCFcAM0RKQgyrI/0Dg/EahmIQyBB0DRvXr4W78tmLV1gtAbmYoEEgnciG4QpTogzCFyEwSyg2CBS2oCAZNQh3cA+hMAJ5AlcKxuVBlOgnNgVjMwyUrQjmamKLGaoZBAAOTFyLnFFW4wAAAABJRU5ErkJggg==);
  }
  .gantt_tree_icon.gantt_file {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAeElEQVQ4T2NkoBJgRDZn+vTp/wmZm5mZiaIHph7DICkJMUJmMfgHBmMYhtUgbAo3rl+L4lp0NUQbBPI2umuRDaPIIFAYwAyjv0HoMQALM5JdhG4QLMxGDcKdyIdoGIE89OzFK4KZF5Rl8EY/QROQFGA1iBQD0NUCAJVjcxO0naAQAAAAAElFTkSuQmCC);
  }
  .gantt_grid_head_cell .gantt_sort {
    position: absolute;
    right: 5px;
    top: 8px;
    width: 7px;
    height: 13px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .gantt_grid_head_cell .gantt_sort.gantt_asc {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAANCAYAAABlyXS1AAAARUlEQVR4nGNgQAKGxib/GbABkIS7b8B/DAUwCRiGK0CXwFBAb1DfP/U/LszwHwi2X7qFgUEArBtdAVwCBmAKMCSQFSDzAWXXaOHsXeqkAAAAAElFTkSuQmCC);
  }
  .gantt_grid_head_cell .gantt_sort.gantt_desc {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAANCAYAAABlyXS1AAAARUlEQVR42mNgQAL1/VP/M2ADIIntF2/9x1AAlrh0C47hCmA60DFYwX88gIFGwNDY5D8uDFbg7hvwHx2jmIBTAlkB0e4BAEjlaNtBWJPnAAAAAElFTkSuQmCC);
  }
  .gantt_inserted,
  .gantt_updated {
    font-weight: bold;
  }
  .gantt_deleted {
    text-decoration: line-through;
  }
  .gantt_invalid {
    background-color: #FFE0E0;
  }
  .gantt_error {
    color: red;
  }
  .gantt_status {
    right: 1px;
    padding: 5px 10px;
    background: rgba(155, 155, 155, 0.1);
    position: absolute;
    top: 1px;
    transition: opacity 0.2s;
    opacity: 0;
  }
  .gantt_status.gantt_status_visible {
    opacity: 1;
  }
  #gantt_ajax_dots span {
    transition: opacity 0.2s;
    background-repeat: no-repeat;
    opacity: 0;
  }
  #gantt_ajax_dots span.gantt_dot_visible {
    opacity: 1;
  }
  .gantt_column_drag_marker {
    border: 1px solid #CECECE;
    opacity: 0.8;
  }
  .gantt_grid_head_cell_dragged {
    border: 1px solid #CECECE;
    opacity: 0.3;
  }
  .gantt_grid_target_marker {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #FFA011;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
  }
  .gantt_grid_target_marker::before,
  .gantt_grid_target_marker::after {
    display: block;
    content: "";
    position: absolute;
    left: -5px;
    width: 0px;
    height: 0px;
    border: 6px solid rgba(0, 0, 0, 0);
  }
  .gantt_grid_target_marker::before {
    border-top-color: #FFA011;
  }
  .gantt_grid_target_marker::after {
    bottom: 0;
    border-bottom-color: #FFA011;
  }
  .gantt_message_area {
    position: fixed;
    right: 5px;
    width: 250px;
    z-index: 1000;
  }
  .gantt-info {
    min-width: 120px;
    padding: 4px 4px 4px 20px;
    font-family: "Arial";
    z-index: 10000;
    margin: 5px;
    margin-bottom: 10px;
    transition: all .5s ease;
  }
  .gantt-info.hidden {
    height: 0px;
    padding: 0px;
    border-width: 0px;
    margin: 0px;
    overflow: hidden;
  }
  .gantt_modal_box {
    overflow: hidden;
    display: inline-block;
    min-width: 250px;
    width: 250px;
    text-align: center;
    position: fixed;
    z-index: 20000;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    font-family: "Arial";
    border-radius: 6px;
    border: 1px solid #CECECE;
    background: #fff;
  }
  .gantt_popup_title {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-width: 0px;
  }
  .gantt_button,
  .gantt_popup_button {
    border: 1px solid #CECECE;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 4px;
    background: #fff;
  }
  .gantt-info,
  .gantt_popup_button,
  .gantt_button {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    cursor: pointer;
  }
  .gantt_popup_text {
    overflow: hidden;
  }
  .gantt_popup_controls {
    border-radius: 6px;
    padding: 10px;
  }
  .gantt_popup_button {
    min-width: 100px;
  }
  div.dhx_modal_cover {
    background-color: #000;
    cursor: default;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
    opacity: 0.2;
    position: fixed;
    z-index: 19999;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border: none;
    zoom: 1;
  }
  .gantt-info img,
  .gantt_modal_box img {
    float: left;
    margin-right: 20px;
  }
  .gantt-alert-error,
  .gantt-confirm-error {
    border: 1px solid #ff0000;
  }
  /*Skin section*/
  .gantt_button input,
  .gantt_popup_button div {
    border-radius: 4px;
    font-size: 14px;
    box-sizing: content-box;
    padding: 0px;
    margin: 0px;
    vertical-align: top;
  }
  .gantt_popup_title {
    border-bottom: 1px solid #CECECE;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
  .gantt_popup_text {
    margin: 15px 15px 5px 15px;
    font-size: 14px;
    color: #000;
    min-height: 30px;
    border-radius: 6px;
  }
  .gantt-info,
  .gantt-error {
    font-size: 14px;
    color: #000;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    padding: 0px;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #FFFFFF;
  }
  .gantt-info div {
    padding: 5px 10px 5px 10px;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #CECECE;
  }
  .gantt-error {
    background-color: #d81b1b;
    border: 1px solid #ff3c3c;
  }
  .gantt-error div {
    background-color: #d81b1b;
    border: 1px solid #940000;
    color: #FFFFFF;
  }
  .gantt-warning {
    background-color: #FF9000;
    border: 1px solid #ffa633;
  }
  .gantt-warning div {
    background-color: #FF9000;
    border: 1px solid #b36500;
    color: #FFFFFF;
  }
  .gantt_grid div,
  .gantt_data_area div {
    -ms-touch-action: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .gantt_data_area {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-user-select: none;
    user-select: none;
  }
  .gantt_links_area {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .gantt_task_content,
  .gantt_task_progress,
  .gantt_side_content {
    line-height: inherit;
    overflow: hidden;
    height: 100%;
  }
  .gantt_task_content {
    font-size: 12px;
    color: #fff;
    width: 100%;
    top: 0;
    cursor: pointer;
    position: absolute;
    white-space: nowrap;
    text-align: center;
  }
  .gantt_task_progress {
    text-align: center;
    z-index: 0;
    background: #299cb4;
  }
  .gantt_task_progress_wrapper {
    border-radius: inherit;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .gantt_task_line {
    border-radius: 2px;
    position: absolute;
    box-sizing: border-box;
    background-color: #3db9d3;
    border: 1px solid #2898b0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
  }
  .gantt_task_line.gantt_drag_move div {
    cursor: move;
  }
  .gantt_touch_move,
  .gantt_touch_progress .gantt_touch_resize {
    -webkit-transform: scale(1.02, 1.1);
    transform: scale(1.02, 1.1);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
  }
  .gantt_touch_progress .gantt_task_progress_drag,
  .gantt_touch_resize .gantt_task_drag {
    -webkit-transform: scaleY(1.3);
    transform: scaleY(1.3);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
  }
  .gantt_side_content {
    position: absolute;
    white-space: nowrap;
    color: #6e6e6e;
    top: 0;
    font-size: 11px;
  }
  .gantt_side_content.gantt_left {
    right: 100%;
    padding-right: 20px;
  }
  .gantt_side_content.gantt_right {
    left: 100%;
    padding-left: 20px;
  }
  .gantt_side_content.gantt_link_crossing {
    bottom: 8.75px;
    top: auto;
  }
  .gantt_task_link .gantt_line_wrapper,
  .gantt_link_arrow {
    position: absolute;
    cursor: pointer;
  }
  .gantt_line_wrapper div {
    background-color: #FFA011;
  }
  .gantt_task_link:hover .gantt_line_wrapper div {
    box-shadow: 0 0 5px 0px #FFA011;
  }
  .gantt_task_link div.gantt_link_arrow {
    background-color: transparent;
    border-style: solid;
    width: 0px;
    height: 0px;
  }
  .gantt_link_control {
    position: absolute;
    width: 20px;
    top: 0px;
  }
  .gantt_link_control div {
    display: none;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    top: 50%;
    margin-top: -7.5px;
    vertical-align: middle;
    border: 1px solid #929292;
    border-radius: 6.5px;
    height: 13px;
    width: 13px;
    background-color: #f0f0f0;
  }
  .gantt_link_control.task_right div.gantt_link_point {
    margin-left: 7px;
  }
  .gantt_link_control div:hover {
    background-color: #FFF;
  }
  .gantt_link_control.task_left {
    left: -20px;
  }
  .gantt_link_control.task_right {
    right: -20px;
  }
  .gantt_task_line.gantt_selected .gantt_link_control div,
  .gantt_task_line:hover .gantt_link_control div,
  .gantt_task_line.gantt_drag_progress .gantt_link_control div,
  .gantt_task_line.gantt_drag_move .gantt_link_control div,
  .gantt_task_line.gantt_drag_resize .gantt_link_control div,
  .gantt_task_line.gantt_selected .gantt_task_progress_drag,
  .gantt_task_line:hover .gantt_task_progress_drag,
  .gantt_task_line.gantt_drag_progress .gantt_task_progress_drag,
  .gantt_task_line.gantt_drag_move .gantt_task_progress_drag,
  .gantt_task_line.gantt_drag_resize .gantt_task_progress_drag,
  .gantt_task_line.gantt_selected .gantt_task_drag,
  .gantt_task_line:hover .gantt_task_drag,
  .gantt_task_line.gantt_drag_progress .gantt_task_drag,
  .gantt_task_line.gantt_drag_move .gantt_task_drag,
  .gantt_task_line.gantt_drag_resize .gantt_task_drag {
    display: block;
  }
  .gantt_link_target .gantt_link_control div {
    display: block;
  }
  .gantt_link_source,
  .gantt_link_target {
    box-shadow: 0px 0px 3px #3db9d3;
  }
  .gantt_link_target.link_start_allow,
  .gantt_link_target.link_finish_allow {
    box-shadow: 0px 0px 3px #ffbf5e;
  }
  .gantt_link_target.link_start_deny,
  .gantt_link_target.link_finish_deny {
    box-shadow: 0px 0px 3px #e87e7b;
  }
  .link_start_allow .gantt_link_control.task_start_date div,
  .link_finish_allow .gantt_link_control.task_end_date div {
    background-color: #ffbf5e;
    border-color: #ffa011;
  }
  .link_start_deny .gantt_link_control.task_start_date div,
  .link_finish_deny .gantt_link_control.task_end_date div {
    background-color: #e87e7b;
    border-color: #dd3e3a;
  }
  .gantt_link_arrow_right {
    border-width: 4px 0 4px 6px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: #FFA011;
    margin-top: -1px;
  }
  .gantt_link_arrow_left {
    border-width: 4px 6px 4px 0;
    margin-top: -1px;
    border-top-color: transparent !important;
    border-right-color: #FFA011;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
  }
  .gantt_link_arrow_up {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #FFA011 transparent;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: #FFA011;
    border-left-color: transparent !important;
  }
  .gantt_link_arrow_down {
    border-width: 4px 6px 0 4px;
    border-top-color: #FFA011;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
  }
  .gantt_task_drag,
  .gantt_task_progress_drag {
    cursor: ew-resize;
    display: none;
    position: absolute;
  }
  .gantt_task_drag.task_right {
    cursor: e-resize;
  }
  .gantt_task_drag.task_left {
    cursor: w-resize;
  }
  .gantt_task_drag {
    height: 100%;
    width: 8px;
    z-index: 1;
    top: -1px;
  }
  .gantt_task_drag.task_left {
    left: -7px;
  }
  .gantt_task_drag.task_right {
    right: -7px;
  }
  .gantt_task_progress_drag {
    height: 8px;
    width: 8px;
    bottom: -4px;
    margin-left: -4px;
    background-position: bottom;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkY3Rjk0RUVDMkYzMTFFMkI1OThEQTA3ODU0OTkzMEEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkY3Rjk0RUZDMkYzMTFFMkI1OThEQTA3ODU0OTkzMEEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyRjdGOTRFQ0MyRjMxMUUyQjU5OERBMDc4NTQ5OTMwQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyRjdGOTRFREMyRjMxMUUyQjU5OERBMDc4NTQ5OTMwQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PobPBzIAAADkSURBVHjaYpk2bRoDDsAExL1QdjEQ/8OmiAWHZk4gXqymqhQM4ty6fU8OSMUA8XdiDBAB4k0a6iqWRga6EKcwMQXduHlnL5DpB8Rv0J2JDFSA+JiOtgZcMwiA2CAxkBxUDVYDLEAKgIpV9XQ0MZwFEgPJAZnHoWpRDAgC4n2W5saiQKfjClQGkBxQDciL+6B6wAbkA/EqJwdrTkUFOQZCAKQGpBbIXA3SCzJggo+XK7OEuBgDsQCkFqgHrBfsBT5eHgZSAUwP2IBfv36TbABMDygdtK1Zv6UESLORaAbIhG6AAAMAKN8wE24DXWcAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    z-index: 1;
  }
  .gantt_task_progress_drag:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVQoz6WMPW7CQBgFJxZaiZ60qcgdwjVMmzu8gpwhDULabXyBdHAGuzRHivQiQZovigS2+Jtu95t5T03TMITtCtjEc5VSOgx5k5F4CnxJWgKUUl5sv6eUvk/daiCeAe1fDCCpBtq4jQ/YngO9pMWpGH99OOcDtt8ifmWEuO3D/R+wXQOdpGcuIGkGdNFQ2RawlTTlSsLd2RY55+O95JyPFQ/y8MAE+CylfADpxvYHWP8CXj+JR4wdKHYAAAAASUVORK5CYII=);
  }
  .gantt_link_tooltip {
    box-shadow: 3px 3px 3px #888888;
    background-color: #fff;
    border-left: 1px dotted #cecece;
    border-top: 1px dotted #cecece;
    font-family: Tahoma;
    font-size: 8pt;
    color: #444;
    padding: 6px;
    line-height: 20px;
  }
  .gantt_link_direction {
    height: 0px;
    border: 0px none #FFA011;
    border-bottom-style: dashed;
    border-bottom-width: 2px;
    transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
    z-index: 2;
    margin-left: 1px;
    position: absolute;
  }
  .gantt_grid_data .gantt_row.gantt_selected,
  .gantt_grid_data .gantt_row.odd.gantt_selected {
    background-color: #FFF3A1;
  }
  .gantt_task_row.gantt_selected {
    background-color: #FFF3A1;
  }
  .gantt_task_row.gantt_selected .gantt_task_cell {
    border-right-color: #ffec6e;
  }
  .gantt_task_line.gantt_selected {
    box-shadow: 0 0 5px #299cb4;
  }
  .gantt_task_line.gantt_project.gantt_selected {
    box-shadow: 0 0 5px #46ad51;
  }
  .gantt_task_line.gantt_milestone {
    visibility: hidden;
    background-color: #D33DAF;
    border: 0px solid #61164f;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
  }
  .gantt_task_line.gantt_milestone div {
    visibility: visible;
  }
  .gantt_task_line.gantt_milestone .gantt_task_content {
    background: inherit;
    border: inherit;
    border-width: 1px;
    border-radius: inherit;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .gantt_task_line.gantt_task_inline_color {
    border-color: #999999;
  }
  .gantt_task_line.gantt_task_inline_color .gantt_task_progress {
    background-color: #363636;
    opacity: 0.2;
  }
  .gantt_task_line.gantt_task_inline_color.gantt_selected {
    box-shadow: 0 0 5px #999999;
  }
  .gantt_task_line.gantt_task_inline_color.gantt_project.gantt_selected {
    box-shadow: 0 0 5px #999999;
  }
  .gantt_task_link.gantt_link_inline_color:hover .gantt_line_wrapper div {
    box-shadow: 0 0 5px 0px #999999;
  }
  .gantt_critical_task {
    background-color: #e63030;
    border-color: #9d3a3a;
  }
  .gantt_critical_task .gantt_task_progress {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .gantt_critical_link .gantt_line_wrapper > div {
    background-color: #e63030;
  }
  .gantt_critical_link .gantt_link_arrow {
    border-color: #e63030;
  }
  .gantt_row:focus,
  .gantt_cell:focus,
  .gantt_btn_set:focus,
  .gantt_qi_big_icon:focus,
  .gantt_popup_button:focus,
  .gantt_grid_head_cell:focus {
    box-shadow: inset 0px 0px 1px 1px #4d90fe;
  }
  .gantt_split_parent,
  .gantt_split_subproject {
    opacity: 0.1;
    pointer-events: none;
  }
  .gantt_unselectable,
  .gantt_unselectable div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
  }
  .gantt_cal_light {
    -webkit-tap-highlight-color: transparent;
    background: #fff;
    border-radius: 6px;
    font-family: "Arial";
    font-size: 13px;
    border: 1px solid #CECECE;
    color: #6b6b6b;
    font-size: 12px;
    position: absolute;
    z-index: 10001;
    width: 550px;
    height: 250px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
  }
  .gantt_cal_light_wide {
    width: 650px;
  }
  .gantt_cal_light select {
    font-family: "Arial";
    border: 1px solid #CECECE;
    font-size: 13px;
    padding: 2px;
    margin: 0px;
  }
  .gantt_cal_ltitle {
    padding: 7px 10px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
  }
  .gantt_cal_ltitle span {
    white-space: nowrap;
  }
  .gantt_cal_lsection {
    color: #727272;
    font-weight: bold;
    padding: 12px 0px 5px 10px;
  }
  .gantt_cal_lsection .gantt_fullday {
    float: right;
    margin-right: 5px;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    vertical-align: top;
    cursor: pointer;
  }
  .gantt_cal_lsection {
    font-size: 13px;
  }
  .gantt_cal_ltext {
    padding: 2px	10px;
    overflow: hidden;
  }
  .gantt_cal_ltext textarea {
    overflow-y: auto;
    overflow-x: hidden;
    font-family: "Arial";
    font-size: 13px;
    box-sizing: border-box;
    border: 1px solid #CECECE;
    height: 100%;
    width: 100%;
    outline: none !important;
    resize: none;
  }
  .gantt_section_constraint [data-constraint-time-select] {
    margin-left: 20px;
  }
  .gantt_time {
    font-weight: bold;
  }
  .gantt_cal_light .gantt_title {
    padding-left: 10px;
  }
  .gantt_cal_larea {
    border: 1px solid #CECECE;
    border-left: none;
    border-right: none;
    background-color: #fff;
    overflow: hidden;
    height: 1px;
  }
  .gantt_btn_set {
    margin: 10px 7px 5px 10px;
    padding: 5px 15px 5px 10px;
    float: left;
    border-radius: 4px;
    border-width: 0;
    border-color: #CECECE;
    border-style: solid;
    height: 32px;
    font-weight: bold;
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
  }
  .gantt_hidden {
    display: none;
  }
  .gantt_btn_set div {
    float: left;
    font-size: 13px;
    height: 22px;
    line-height: 22px;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
  .gantt_save_btn {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTk1OUU5RDFDMzA0MTFFMkExMUZBQTdDNDAzOUE5RjMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTk1OUU5RDJDMzA0MTFFMkExMUZBQTdDNDAzOUE5RjMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxOTU5RTlDRkMzMDQxMUUyQTExRkFBN0M0MDM5QTlGMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxOTU5RTlEMEMzMDQxMUUyQTExRkFBN0M0MDM5QTlGMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjDroXYAAAEXSURBVHjaYvz//z8DJYCRUgPIAUxAbAnEHiAHMIBcQCwGaRYXF3e6evXqoffv39/dv38/CymaGSUkJBzv3LlzCsj///fv3wdAihkkIQnEvkAshU8zLy+v7a1bt06ANP/79+87kDIAy505cybq06dPr3p7ezuwGQLTfOPGjWP/ESAZLg8kPKBO+g01RBJNszWyZqC6uSgWgIg/f/4shxnS2dnZBjMEqNkSFGBImi8CKTYMA4BYCGjIczRDHC5dunQQSfN7IKWI4UUkjjdMMdCwnw8ePLjwHxV4Yw1gZA5Q47z/2EELzhhCE+ABGvIQWSeQvwcU38QaAML2wHj+C/X3MyAlijeB4ZBoBOIPQGxJKIVSnBsBAgwABddBclWfcZUAAAAASUVORK5CYII=);
    margin-top: 2px;
    width: 21px;
  }
  .gantt_cancel_btn {
    margin-top: 2px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDkzMDA3MzlDMzA0MTFFMjg2QTVFMzFEQzgwRkJERDYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDkzMDA3M0FDMzA0MTFFMjg2QTVFMzFEQzgwRkJERDYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowOTMwMDczN0MzMDQxMUUyODZBNUUzMURDODBGQkRENiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowOTMwMDczOEMzMDQxMUUyODZBNUUzMURDODBGQkRENiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmYuYOUAAAEdSURBVHjaYvz//z8DJYAFXWDlypU8QKoIiD2A2AwqfAqIdwBxX3h4+Bdk9YzILgBqtgdS84FYEYeF94E4EWjIQZgAE5LmQCB1AKoZZKMPEAtAMYh9GSp3AKjWD8UFQAEhIPshEIOc3wHENUBb/qJ57SyQMoJyPwKxElDNO1gYFEE17wMKVmIJlzNQzeegrjaA6qmBecEbSvfh0GwMxGeBhoPoemQ9MAO0kEIbl2YTqPAFKK2IbMB3AjabYIkRZmQD7kNpMyI0G0PpO8gGbIUFJj7NQDk2INWIrIcJKfBAKcwJqvkcDs0TgFgXGo19KCkRmpDWQdWDEk0NUoCBoq0FqhkE/IEWbKJKUmZEz43QzFSKIzN1481M5ACAAAMAlfl/lCwRpagAAAAASUVORK5CYII=);
    width: 20px;
  }
  .gantt_delete_btn {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjFENzI3NUNDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjFENzI3NURDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMUQ3Mjc1QUMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMUQ3Mjc1QkMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmUD0gAAAABvSURBVHjaYvz//z8DIyMjAxYQicReji4J0ofKQNP8HwmgGQbXB8IsWGwDSSwDuioKjY9uBthVjFAXYHUGAQA2kYmBUoAUBpGk0LAwgBvwH+YX4mkwptgLowYMRgOITUyYKRFIN/wnDjQgJySAAAMApryKzL8wjfUAAAAASUVORK5CYII=);
    margin-top: 2px;
    width: 20px;
  }
  .gantt_cal_cover {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    top: 0px;
    left: 0px;
    background-color: black;
    opacity: 0.1;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=10);
  }
  .gantt_custom_button {
    padding: 0px 3px 0px 3px;
    font-family: "Arial";
    font-size: 13px;
    font-weight: normal;
    margin-right: 10px;
    margin-top: -5px;
    cursor: pointer;
    float: right;
    height: 21px;
    width: 90px;
    border: 1px solid #CECECE;
    text-align: center;
    border-radius: 4px;
  }
  .gantt_custom_button div {
    cursor: pointer;
    float: none;
    height: 21px;
    line-height: 21px;
    vertical-align: middle;
  }
  .gantt_custom_button div:first-child {
    display: none;
  }
  .gantt_cal_light_wide {
    width: 580px;
    padding: 2px 4px;
  }
  .gantt_cal_light_wide .gantt_cal_larea {
    box-sizing: border-box;
    border: 1px solid #CECECE;
  }
  .gantt_cal_light_wide .gantt_cal_lsection {
    border: 0px;
    float: left;
    text-align: right;
    width: 80px;
    height: 20px;
    padding: 5px 10px 0px 0px;
  }
  .gantt_cal_light_wide .gantt_wrap_section {
    position: relative;
    padding: 10px 0;
    overflow: hidden;
    border-bottom: 1px solid #ebebeb;
  }
  .gantt_cal_light_wide .gantt_section_time {
    overflow: hidden;
    padding-top: 2px !important;
    padding-right: 0px;
    height: 20px !important;
  }
  .gantt_cal_light_wide .gantt_cal_ltext {
    padding-right: 0px;
  }
  .gantt_cal_light_wide .gantt_cal_larea {
    padding: 0 10px;
    width: 100%;
  }
  .gantt_cal_light_wide .gantt_section_time {
    background: transparent;
  }
  .gantt_cal_light_wide .gantt_cal_checkbox label {
    padding-left: 0px;
  }
  .gantt_cal_light_wide .gantt_cal_lsection .gantt_fullday {
    float: none;
    margin-right: 0px;
    font-weight: bold;
    cursor: pointer;
  }
  .gantt_cal_light_wide .gantt_custom_button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
  }
  .gantt_cal_light_wide .gantt_repeat_right {
    margin-right: 55px;
  }
  .gantt_cal_light_wide.gantt_cal_light_full {
    width: 738px;
  }
  .gantt_cal_wide_checkbox input {
    margin-top: 8px;
    margin-left: 14px;
  }
  .gantt_cal_light input {
    font-size: 13px;
  }
  .gantt_section_time {
    background-color: white;
    white-space: nowrap;
    padding: 2px 10px 5px;
    padding-top: 2px !important;
  }
  .gantt_section_time .gantt_time_selects {
    float: left;
    height: 25px;
  }
  .gantt_section_time .gantt_time_selects select {
    height: 23px;
    padding: 2px;
    border: 1px solid #CECECE;
  }
  .gantt_duration {
    width: 100px;
    height: 23px;
    float: left;
    white-space: nowrap;
    margin-left: 20px;
    line-height: 23px;
  }
  .gantt_duration .gantt_duration_value,
  .gantt_duration .gantt_duration_dec,
  .gantt_duration .gantt_duration_inc {
    box-sizing: border-box;
    text-align: center;
    vertical-align: top;
    height: 100%;
    border: 1px solid #CECECE;
  }
  .gantt_duration .gantt_duration_value {
    width: 40px;
    padding: 3px 4px;
    border-left-width: 0;
    border-right-width: 0;
  }
  .gantt_duration .gantt_duration_value.gantt_duration_value_formatted {
    width: 70px;
  }
  .gantt_duration .gantt_duration_dec,
  .gantt_duration .gantt_duration_inc {
    width: 20px;
    padding: 1px;
    padding-bottom: 1px;
    background: #fff;
  }
  .gantt_duration .gantt_duration_dec {
    -moz-border-top-left-radius: 4px;
    -moz-border-bottom-left-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .gantt_duration .gantt_duration_inc {
    margin-right: 4px;
    -moz-border-top-right-radius: 4px;
    -moz-border-bottom-right-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .gantt_resources {
    max-height: 150px;
    height: auto;
    overflow-y: auto;
  }
  .gantt_resource_row {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
  }
  .gantt_resource_row input[type=checkbox]:not(:checked),
  .gantt_resource_row input[type=checkbox]:not(:checked) ~ div {
    opacity: 0.5;
  }
  .gantt_resource_toggle {
    vertical-align: middle;
  }
  .gantt_resources_filter .gantt_resources_filter_input {
    padding: 1px 2px 1px 2px;
    box-sizing: border-box;
  }
  .gantt_resources_filter .switch_unsetted {
    vertical-align: middle;
  }
  .gantt_resource_cell {
    display: inline-block;
  }
  .gantt_resource_cell.gantt_resource_cell_checkbox {
    width: 24px;
    max-width: 24px;
    min-width: 24px;
    vertical-align: middle;
  }
  .gantt_resource_cell.gantt_resource_cell_label {
    width: 40%;
    max-width: 40%;
    vertical-align: middle;
  }
  .gantt_resource_cell.gantt_resource_cell_value {
    width: 30%;
    max-width: 30%;
    vertical-align: middle;
  }
  .gantt_resource_cell.gantt_resource_cell_value input,
  .gantt_resource_cell.gantt_resource_cell_value select {
    width: 80%;
    vertical-align: middle;
    padding: 1px 2px 1px 2px;
    box-sizing: border-box;
  }
  .gantt_resource_cell.gantt_resource_cell_unit {
    width: 10%;
    max-width: 10%;
    vertical-align: middle;
  }
  .gantt_resource_early_value {
    opacity: 0.8;
    font-size: 0.9em;
  }
  /* Quick info */
  .gantt_cal_quick_info {
    border: 1px solid #CECECE;
    border-radius: 6px;
    position: absolute;
    z-index: 300;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    width: 300px;
    transition: left 0.5s ease, right 0.5s;
    -moz-transition: left 0.5s ease, right 0.5s;
    -webkit-transition: left 0.5s ease, right 0.5s;
    -o-transition: left 0.5s ease, right 0.5s;
  }
  .gantt_no_animate {
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
  }
  .gantt_cal_quick_info.gantt_qi_left .gantt_qi_big_icon {
    float: right;
  }
  .gantt_cal_qi_title {
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    padding: 5px 0px 8px 12px;
    color: #454545;
    background-color: #fff;
    border-bottom: 1px solid #CECECE;
  }
  .gantt_cal_qi_tdate {
    font-size: 14px;
    font-weight: bold;
  }
  .gantt_cal_qi_tcontent {
    font-size: 13px;
  }
  .gantt_cal_qi_content {
    padding: 16px 8px;
    font-size: 13px;
    color: #454545;
    overflow: hidden;
  }
  .gantt_cal_qi_controls {
    -webkit-border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 6px;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-bottomleft: 6px;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 6px;
    padding-left: 7px;
  }
  .gantt_cal_qi_controls .gantt_menu_icon {
    margin-top: 6px;
    background-repeat: no-repeat;
  }
  .gantt_cal_qi_controls .gantt_menu_icon.icon_edit {
    width: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH3QYFCjI5ZQj5bAAAAFNJREFUOMvt0zEOACAIA0DkwTymH8bJTRTKZGJXyaWEKPKTCQAH4Ls37cItcDUzsxHNDLZNhCq7Gt1wh9ErV7EjyGAhyGLphlnsClWuS32rn0czAV+vNGrM/LBtAAAAAElFTkSuQmCC);
  }
  .gantt_cal_qi_controls .gantt_menu_icon.icon_delete {
    width: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjFENzI3NUNDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjFENzI3NURDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMUQ3Mjc1QUMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMUQ3Mjc1QkMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmUD0gAAAABvSURBVHjaYvz//z8DIyMjAxYQicReji4J0ofKQNP8HwmgGQbXB8IsWGwDSSwDuioKjY9uBthVjFAXYHUGAQA2kYmBUoAUBpGk0LAwgBvwH+YX4mkwptgLowYMRgOITUyYKRFIN/wnDjQgJySAAAMApryKzL8wjfUAAAAASUVORK5CYII=);
  }
  .gantt_qi_big_icon {
    font-size: 13px;
    border-radius: 4px;
    font-weight: bold;
    background: #fff;
    margin: 5px 9px 8px 0px;
    min-width: 60px;
    line-height: 32px;
    vertical-align: middle;
    padding: 0px 10px 0px 5px;
    cursor: pointer;
    border: 1px solid #CECECE;
  }
  .gantt_cal_qi_controls div {
    float: left;
    height: 32px;
    text-align: center;
    line-height: 32px;
  }
  .gantt_tooltip {
    padding: 10px;
    position: absolute;
    z-index: 50;
    white-space: nowrap;
  }
  .gantt_resource_marker {
    position: absolute;
    text-align: center;
    font-size: 14px;
    color: #FFF;
  }
  .gantt_resource_marker_ok {
    background: rgba(78, 208, 134, 0.75);
  }
  .gantt_resource_marker_overtime {
    background: rgba(255, 134, 134, 0.69);
  }
  .gantt_histogram_label {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    font-weight: bold;
    font-size: 13px;
  }
  .gantt_histogram_fill {
    background-color: rgba(41, 157, 180, 0.2);
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .gantt_histogram_hor_bar {
    height: 1px;
    position: absolute;
    background: #299DB4;
    margin-top: -1px;
    margin-left: -1px;
  }
  .gantt_histogram_vert_bar {
    width: 1px;
    position: absolute;
    background: #299DB4;
    margin-left: -1px;
  }
  .gantt_histogram_cell {
    position: absolute;
    text-align: center;
    font-size: 13px;
    color: #000000;
  }
  .gantt_marker {
    height: 100%;
    width: 2px;
    top: 0;
    position: absolute;
    text-align: center;
    background-color: rgba(255, 0, 0, 0.4);
    box-sizing: border-box;
  }
  .gantt_marker .gantt_marker_content {
    padding: 5px;
    background: inherit;
    color: white;
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.8;
  }
  .gantt_marker_area {
    position: absolute;
    top: 0;
    left: 0;
  }
  .gantt_grid_editor_placeholder {
    position: absolute;
  }
  .gantt_grid_editor_placeholder > div,
  .gantt_grid_editor_placeholder input,
  .gantt_grid_editor_placeholder select {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .gantt_row_placeholder div {
    opacity: 0.5;
  }
  .gantt_row_placeholder .gantt_file,
  .gantt_row_placeholder .gantt_add {
    display: none;
  }
  .gantt_drag_marker.gantt_grid_dnd_marker {
    background-color: transparent;
    transition: all 0.1s ease ;
  }
  .gantt_grid_dnd_marker_line {
    height: 4px;
    width: 100%;
    background-color: #3498db;
  }
  .gantt_grid_dnd_marker_line::before {
    background: #fff;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border: 3px solid #3498db;
    border-radius: 6px;
    content: "";
    line-height: 1px;
    display: block;
    position: absolute;
    margin-left: -11px;
    margin-top: -4px;
    pointer-events: none;
  }
  .gantt_grid_dnd_marker_folder {
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    box-shadow: 0 0 0px 2px #3f98db inset;
    background: transparent;
  }
  .gantt_overlay_area {
    position: absolute;
    height: inherit;
    width: inherit;
    top: 0;
    left: 0;
    display: none;
  }
  .gantt_overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    width: inherit;
  }
  .gantt_click_drag_rect {
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid #3f98db;
    background-color: rgba(52, 152, 219, 0.3);
  }
  .gantt_timeline_move_available,
  .gantt_timeline_move_available * {
    cursor: move;
  }
  .gantt_rtl .gantt_grid {
    text-align: right;
  }
  .gantt_rtl .gantt_row,
  .gantt_rtl .gantt_cell {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .gantt_layout_content {
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
  }
  .gantt_layout_cell {
    position: relative;
    box-sizing: border-box;
  }
  .gantt_layout_cell > .gantt_layout_header {
    background: #33aae8;
    color: white;
    font-size: 17px;
    padding: 5px 10px;
    box-sizing: border-box;
  }
  .gantt_layout_header.collapsed_x {
    background: #a9a9a9;
  }
  .gantt_layout_header.collapsed_x .gantt_header_arrow:before {
    content: "\21E7";
  }
  .gantt_layout_header.collapsed_y {
    background: #a9a9a9;
  }
  .gantt_layout_header.collapsed_y .gantt_header_arrow:before {
    content: "\21E9";
  }
  .gantt_layout_header {
    cursor: pointer;
  }
  .gantt_layout_header .gantt_header_arrow {
    float: right;
    text-align: right;
  }
  .gantt_layout_header .gantt_header_arrow:before {
    content: "\21E6";
  }
  .gantt_layout_header.vertical .gantt_header_arrow:before {
    content: "\21E7";
  }
  .gantt_layout_outer_scroll_vertical .gantt_layout_content {
    overflow-y: hidden;
  }
  .gantt_layout_outer_scroll_horizontal .gantt_layout_content {
    overflow-x: hidden;
  }
  .gantt_layout_x > .gantt_layout_cell {
    display: inline-block;
    vertical-align: top;
  }
  .gantt_layout_x {
    white-space: nowrap;
  }
  .gantt_resizing {
    opacity: 0.7;
    background: #f2f2f2;
  }
  .gantt_layout_cell_border_right.gantt_resizer {
    overflow: visible;
    border-right: 0;
  }
  .gantt_resizer {
    cursor: e-resize;
    position: relative;
  }
  .gantt_resizer_y {
    cursor: n-resize;
  }
  .gantt_resizer_stick {
    background: #33aae8;
    z-index: 9999;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .gantt_resizer_x .gantt_resizer_x {
    position: absolute;
    width: 20px;
    height: 100%;
    margin-left: -10px;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .gantt_resizer_y .gantt_resizer_y {
    position: absolute;
    height: 20px;
    width: 100%;
    top: -10px;
    left: 0;
    z-index: 1;
  }
  .gantt_resizer_error {
    background: indianred!important;
  }
  .gantt_noselect {
    -webkit-user-select: none;
    user-select: none;
  }
  .gantt_layout_cell_border_left {
    border-left: 1px solid #CECECE;
  }
  .gantt_layout_cell_border_right {
    border-right: 1px solid #CECECE;
  }
  .gantt_layout_cell_border_top {
    border-top: 1px solid #CECECE;
  }
  .gantt_layout_cell_border_bottom {
    border-bottom: 1px solid #CECECE;
  }
  .gantt_layout_cell_border_transparent {
    border-color: transparent;
  }
  .gantt_window {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999999999;
    background: white;
  }
  .gantt_window_content {
    position: relative;
  }
  .gantt_window_content_header {
    background: #39c;
    color: #ffffff;
    height: 33px;
    padding: 10px 10px 0 10px;
    border-bottom: solid 2px #ffffff;
    position: relative;
  }
  .gantt_window_content_header_text {
    padding-left: 10%;
  }
  .gantt_window_content_header_buttons {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .gantt_window_content_header_buttons:hover {
    color: #000000;
    cursor: pointer;
  }
  .gantt_window_content_resizer {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    line-height: 15px;
    right: -1px;
    text-align: center;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAABZJREFUeAFjIAUwUshlpJDLSIhLGAAACQ4AFk79JaMAAAAASUVORK5CYII=);
    cursor: nw-resize;
    z-index: 999;
  }
  .gantt_window_content_frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  .gantt_window_drag {
    cursor: pointer!important;
  }
  .gantt_window_resizing {
    overflow: visible;
  }
  .gantt_window_resizing_body {
    overflow: hidden!important;
  }
  .gantt_window_modal {
    background: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
  }
  .gantt_container,
  .gantt_cal_light,
  .gantt_message_area,
  .gantt_modal_box,
  .gantt_cal_quick_info,
  .gantt_tooltip {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .gantt_noselect {
    -webkit-user-select: none;
    user-select: none;
  }
  .gantt_drag_marker {
    position: absolute;
    top: -1000px;
    left: -1000px;
    font-family: "Arial";
    font-size: 13px;
    z-index: 1;
    white-space: nowrap;
  }
  .gantt_drag_marker .gantt_tree_indent,
  .gantt_drag_marker .gantt_tree_icon.gantt_blank,
  .gantt_drag_marker .gantt_tree_icon.gantt_open,
  .gantt_drag_marker .gantt_tree_icon.gantt_close {
    display: none;
  }
  .gantt_drag_marker,
  .gantt_drag_marker .gantt_row.odd {
    background-color: #FFFFFF;
  }
  .gantt_drag_marker .gantt_row {
    border-left: 1px solid #d2d2d2;
    border-top: 1px solid #d2d2d2;
  }
  .gantt_drag_marker .gantt_cell {
    border-color: #d2d2d2;
  }
  .gantt_row.gantt_over,
  .gantt_task_row.gantt_over {
    background-color: #0070fe;
  }
  .gantt_row.gantt_transparent .gantt_cell {
    opacity: 0.7;
  }
  .gantt_task_row.gantt_transparent {
    background-color: #f8fdfd;
  }
  .gantt_popup_button.gantt_delete_button {
    background: #3db9d3;
    text-shadow: 0 -1px 0 #248a9f;
    color: #fff;
    font-weight: bold;
    border-width: 0;
  }
  .gantt_container_resize_watcher {
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    pointer-events: none;
    border: 0;
    box-sizing: border-box;
    opacity: 0;
  }
  
  