.high {
  border: 2px solid #d96c49;
  color: #d96c49;
  background: #d96c49;
}
.high .gantt_task_progress {
  background: #db2536 !important;
}

.medium {
  border: 2px solid #34c461;
  color: #34c461;
  background: #34c461;
}
.medium .gantt_task_progress {
  background: #23964d;
}

.low {
  border: 2px solid #6ba8e3;
  color: #6ba8e3;
  background: #6ba8e3;
}
.low .gantt_task_progress {
  background: #547dab;
}
.gant-modal-body,
.gant-wrapper,
.main-content {
  height: 100%;
  position: relative;
}

.gantt_cal_cover {
  display: none;
}
.gantt_section_time {
  text-align: left !important;
}

.gantt_scale_cell {
  line-height: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
@media (max-width: 768px) {
  .gantt_cal_light {
    width: 90% !important;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    position: fixed !important;
  }
  .gantt_cal_light input {
    width: 100% !important;
    max-width: 200px !important;
    padding: 5px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    border: 1px solid #ddd !important;
    margin: 0 3px !important;
  }
  .gantt_cal_light select {
    width: 100% !important;
    padding: 5px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    border: 1px solid #ddd !important;
    margin: 0 3px !important;
  }
  .gantt_cal_ltext,
  .gantt_cal_lsection,
  .gantt_cal_ltext,
  .gantt_cal_larea {
    height: auto !important;
  }
  .gantt_cal_larea {
    padding-bottom: 10px;
  }
}

.gantt_cal_lsection {
  width: auto !important;
  float: none !important;
  text-align: left !important;
  font-size: 15px !important;
  display: block !important;
  margin-bottom: 5px !important;
  border-bottom: 1px solid #ddd !important;
  height: auto !important;
  padding-bottom: 5px !important;
}
.gantt_cal_light,
.gantt_cal_light_wide,
.gantt_wrap_section {
  height: auto !important;
  overflow: auto !important;
}
.gantObj_cal_ltext,
.gantt_cal_larea,
.gantt_section_time {
  height: auto !important;
}
.gantt_cal_light_wide .gantt_section_time,
.gantt_cal_ltext {
  height: auto !important;
}
@media (max-width: 650px) {
  .gantt_section_time select {
    width: 44px !important;
    margin: 0 !important;
    font-size: 12px !important;
    padding: 10px 0 !important;
  }
  .gantt_section_time {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.gantt_task_progress {
  text-align: center;
  z-index: 0;
  background: #b6263d;
}
.gantt_cal_lsection{
  display:none!important;;
}

.mission_task{
  border:1px solid #304CCE;
  color:#6ba8e3;
  background: #556cd6;
}
.mission_task .gantt_task_progress{
  background:#304CCE;
}

.resource_obj_item{
  color:black;
}


.fa {
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  opacity: 1;
  padding: 5px;
  transition:0.3;
}

.fa:hover {
  opacity: 0.6;
}

.fa-pencil {
  color: #ffa011;
}

.fa-plus {
  color: #328EA0;
}

.fa-times {
  color: red;
}

.gantt_cal_light{
  position:fixed!important;
  top:50%!important;
  left:50%!important;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  box-shadow:0 0 16px rgba(0,0,0,0.16);
  margin:0!important;
}

.this-week {
  background-color: #EEE;
}


@media(max-width:650px){
  .gantt_section_time span{
  display:block;
  text-align:center;
  height:20px;
  width:100%;
}
.gantt_section_time{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap;
}
.gantt_section_time select{
  -webkit-flex-grow:1;
          flex-grow:1;
  margin:0 5px!important;
}
#input_1617417296381{
  min-width:99%;
  box-sizing:border-box;
}
.gantObj_cal_ltext{
  display:-webkit-flex;
  display:flex;
  white-space: nowrap;
  -webkit-align-items:center;
          align-items:center;
}
}

@media(max-width:650px){
  .title-toggle .MuiTypography-root{
    font-size:10px;
  }
}
.this-week {
  background-color: #e26579;
  color: white !important;
}
#lightbox_here.active{
  position: fixed;
  z-index:0;
  background-color:rgba(0,0,0,0.6);
  height:100%;
  width:100%;
  top:0;
  left:0;
}